import React, { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import clsx from 'clsx';
//styles
import styles from './team-augmentation.module.css';
//other
import situations from './situations.json';

const SituationTable = () => {
  const [situationNumber, setSituationNumber] = useState(0);
  const breakpoint = useBreakpoint();
  return (
    <div className={styles.situationTable}>
      <ul className={styles.situationTitleList}>
        <li className={styles.situationItem} key="Situation 1">
          <div
            className={
              situationNumber === 0
                ? clsx(styles.situationTitleItem, styles.situationItemActive)
                : styles.situationTitleItem
            }
            onClick={() => setSituationNumber(0)}
          >
            <p className={styles.situationItemTitle}>Situation 1</p>
            <p className={styles.situationItemText}>Lack of expertise</p>
          </div>

          {breakpoint.s && situationNumber === 0 && (
            <div className={styles.situationSolutionBox}>
              <p className={styles.situationSolutionText}>{situations[0].poblem}</p>
              <p className={styles.situationSolution}>Solution</p>
              <p className={styles.situationSolutionText}>{situations[0].solution}</p>
            </div>
          )}
        </li>
        <li className={styles.situationItem} key="Situation 2">
          <div
            className={
              situationNumber === 1
                ? clsx(styles.situationTitleItem, styles.situationItemActive)
                : styles.situationTitleItem
            }
            onClick={() => setSituationNumber(1)}
          >
            <p className={styles.situationItemTitle}>Situation 2</p>
            <p className={styles.situationItemText}>Staffing shortage</p>
          </div>

          {breakpoint.s && situationNumber === 1 && (
            <div className={styles.situationSolutionBox}>
              <p className={styles.situationSolutionText}>{situations[1].poblem}</p>
              <p className={styles.situationSolution}>Solution</p>
              <p className={styles.situationSolutionText}>{situations[1].solution}</p>
            </div>
          )}
        </li>
        <li className={styles.situationItem} key="Situation 3">
          <div
            className={
              situationNumber === 2
                ? clsx(styles.situationTitleItem, styles.situationItemActive)
                : styles.situationTitleItem
            }
            onClick={() => setSituationNumber(2)}
          >
            <p className={styles.situationItemTitle}>Situation 3</p>
            <p className={styles.situationItemText}>New priorities</p>
          </div>

          {breakpoint.s && situationNumber === 2 && (
            <div className={styles.situationSolutionBox}>
              <p className={styles.situationSolutionText}>{situations[2].poblem}</p>
              <p className={styles.situationSolution}>Solution</p>
              <p className={styles.situationSolutionText}>{situations[2].solution}</p>
            </div>
          )}
        </li>
      </ul>
      {!breakpoint.s && (
        <div className={styles.situationSolutionBox}>
          <p className={styles.situationSolutionText}>
            {' '}
            {situations.find((_, ind) => ind === situationNumber)?.poblem}
          </p>
          <p className={styles.situationSolution}>Solution</p>
          <p className={styles.situationSolutionText}>
            {situations.find((_, ind) => ind === situationNumber)?.solution}
          </p>
        </div>
      )}
    </div>
  );
};

export default SituationTable;
