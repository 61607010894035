import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
// gatsby
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import clsx from 'clsx';
//styles
import mvpStyles from '../services.module.css';
import styles from './team-augmentation.module.css';
//components
import Booking from 'components/feedback';
import Layout from '../components/layout';
import Technology from 'components/technology';
import Awards from 'components/about-us/awards';
import OfferWidget from './offer-wiget';
import TwoPizzaTeam from './two-pizza-team';
import Achievements from 'components/recognition/achievements';
import ReviewSlider1 from './review-slider1';
import ReviewSlider2 from './review-slider2';
import SituationTable from './situationTable';
//meta
import { meta } from '../../../metaData';
import identificators from 'components/googleAnalyticsIdentificators';

interface Props {
  location: Location;
}

const TeamAugmentation = ({ location }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      soundChallange: file(relativePath: { eq: "sound-challange.svg" }) {
        publicURL
      }
      soundChallangeMob: file(relativePath: { eq: "sound-challange-mob.svg" }) {
        publicURL
      }
      arrow: file(relativePath: { eq: "arrow.svg" }) {
        publicURL
      }
      offerMob: file(relativePath: { eq: "offer-mob.svg" }) {
        publicURL
      }
      offer: file(relativePath: { eq: "offer.svg" }) {
        publicURL
      }
      cooperationIcon: file(relativePath: { eq: "cooperation-icon.svg" }) {
        publicURL
      }
      workingOnMacbook: file(relativePath: { eq: "working-on-macbook.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      workingOnMacbookMob: file(relativePath: { eq: "working-on-macbook-mob.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const breakpoint = useBreakpoint();

  return (
    <Layout
      title="Tackle Your Tech Skills Gap in the Shortest Time Possible"
      subtitle="Get top tech talent with Brocoders ‒ we provide team augmentation services to strengthen your project with the required skill sets ‒ fast and effortlessly."
      btnText="CONTACT US"
      metaData={{ main: meta.teamAugmentation }}
      pathname={location.pathname}
      withMovie
      getInTouchTextColor="#000"
      subtitleStyles={styles.header}
      titleStyles={styles.headerTitle}
    >
      <section className={mvpStyles.section}>
        <Achievements />
      </section>
      <section className={mvpStyles.section}>
        <ReviewSlider1 />
      </section>
      <section className={mvpStyles.section}>
        <div className="inner">
          <h2 className={styles.title}>Our solutions for your talent gap </h2>
          <p className="subtitle">
            Expand your in-house team with our software engineers or let us build a cross-functional
            dedicated team for you.
          </p>
        </div>
      </section>
      <section className={mvpStyles.section}>
        <div className="inner">
          <h2 className={clsx(styles.subtitle, styles.subtitleGap)}>
            Hire top-notch software engineers to work alongside your team
          </h2>
          <p className={styles.paragraph}>
            From Node.js programmers to cloud engineers to blockchain pros – hire experienced
            software developers from Brocoders. Our engineering team can help you handle the
            increased workload, speed up delivery and improve your product quality. We’ll work
            closely with you to provide the best possible fit.
          </p>
        </div>
      </section>
      <section className={mvpStyles.section}>
        <div className="inner">
          <div className={styles.technicalStack}>
            <Technology
              list={[
                'react',
                'nodejs',
                'reactNative',
                'expressJs',
                'gatsby',
                'mongo',
                'blockchain',
                'azure_aws',
              ]}
              listStyle={styles.techList}
            />
          </div>
        </div>
      </section>
      <div className={styles.pinkContainer}>
        <p className={styles.findCandidatesTitle}>
          {'Need experts in technologies \n that are not listed here?'}
        </p>
        <p className={styles.findCandidatesText}>
          Let us know. With our extensive talent database and strong recruitment department, we can
          find candidates with any skill set.
        </p>
        <Link
          id={identificators.HIRING_CONTACT_US}
          className="btn btn_60 btn__white wideBtn"
          to="/get-in-touch/"
        >
          Contact us
        </Link>
      </div>
      <div className="inner">
        <TwoPizzaTeam isMobile={breakpoint.s} />
      </div>
      <div className={styles.containerOffer}>
        <div className={styles.wrapperOffer}>
          {breakpoint.s ? (
            <img src={data.offerMob.publicURL} className={styles.offerIcon} alt="offer picture" />
          ) : (
            <img src={data.offer.publicURL} className={styles.offerIcon} alt="offer picture" />
          )}
          <div className={styles.offerBox}>
            <p className={styles.offerText}>
              Tell us about your product and current challenges and we’ll get back to you with our
              next steps.
            </p>
            <Link
              id={identificators.GET_STARTED}
              className={clsx('btn btn_60 btn__black wideBtn', styles.linkOffer)}
              to="/get-in-touch/"
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
      <section className={clsx(mvpStyles.section, styles.situationTabsSection)}>
        <div className={styles.situationTabsContainer}>
          <h2 className={styles.expertiseTitle}>When we’re the best fit</h2>
          <p className={styles.expertisePraragraph}>
            If you find yourself in one of the situations described below, our{' '}
            <span className={styles.expertiseTextBold}>staff augmentation</span> service is exactly
            what you need.
          </p>
          <SituationTable />
        </div>
      </section>
      <div className={styles.soundChallangeContainer}>
        {breakpoint.s ? (
          <img
            src={data.soundChallangeMob.publicURL}
            className={styles.soundChallangeIcon}
            alt="Icon of sound Challange"
          />
        ) : (
          <img
            src={data.soundChallange.publicURL}
            className={styles.soundChallangeIcon}
            alt="Icon of sound Challange"
          />
        )}
        <div className={styles.soundChallangeBox}>
          {!breakpoint.m && (
            <img src={data.arrow.publicURL} className={styles.arrowIcon} alt="icon of arrow" />
          )}
          <Link
            id={identificators.LETS_TALK}
            className={clsx('btn btn_60 btn__black wideBtn', styles.linkChallange)}
            to="/get-in-touch/"
          >
            Let's talk
          </Link>
        </div>
      </div>

      <section className={styles.typeOfWorkSection}>
        <div className={styles.typeOfWorkOuterContainer}>
          <div className={styles.typeOfWorkContainer}>
            <h3 className={styles.typeOfWorkTitle}>The type of work we get hired for</h3>
            <div className={styles.typeOfWorkBox}>
              <p className={clsx(styles.expertisePraragraph, styles.typeOfWorkText)}>
                It doesn't matter what kind of product you're building. Brocoders can implement a
                project in any industry. Our main strengths lie within the fields of Agile product
                development, software engineering, and cloud architecture.
              </p>

              <ul className={styles.typeOfWorkList}>
                <li className={styles.typeOfWorkItem}>MVP development</li>
                <li className={styles.typeOfWorkItem}>Splitting monoliths into microservices</li>
                <li className={styles.typeOfWorkItem}>Cloud migration assistance</li>
                <li className={styles.typeOfWorkItem}>New functionality development</li>
                <li className={styles.typeOfWorkItem}>Long-term project development</li>
                <li className={styles.typeOfWorkItem}>Support and maintenance</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className={mvpStyles.section}>
        <div className={styles.technicalAuditContainer}>
          <img
            src={data.cooperationIcon.publicURL}
            className={styles.technicalAuditLogo}
            alt="technical Audit Logo"
          />
          <div className={styles.technicalAuditBox}>
            <h3 className={styles.technicalAuditTitle}>Start with a technical audit</h3>
            <p className={styles.technicalAuditText}>
              We’ll review your app from a technical perspective and provide an actionable report
              from our senior developers on how to fix what needs fixing. This way you can evaluate
              our skills and decide whether you want to work together going forward.
            </p>
            <a href="https://hubs.ly/Q01s5p1K0" className={styles.technicalAuditLink}>
              download tech audit report
            </a>
            <Link
              id={identificators.GET_TECH_AUDIT}
              className="btn btn_60 btn__white wideBtn"
              to="/get-in-touch/"
            >
              Get a tech audit
            </Link>
          </div>
          {breakpoint.s ? (
            <Img
              fluid={data.workingOnMacbookMob.childImageSharp.fluid}
              className={styles.bg}
              alt=""
            />
          ) : (
            <Img
              fluid={data.workingOnMacbook.childImageSharp.fluid}
              className={styles.technicalAuditImg}
              alt=""
            />
          )}
        </div>
      </section>
      <section className={mvpStyles.section}>
        <div className={clsx('inner-container', styles.expertiseContainer)}>
          <h2 className={styles.subtitle}>Why extend your team with our help</h2>
          <p className={styles.expertisePraragraph}>
            Expertise, cost-efficiency, and speed ‒ Brocoders offers all of these benefits. We're a
            reliable <span className={styles.expertiseTextBold}>IT staff augmentation </span>partner
            for technology companies.
          </p>
          <ul className={styles.expertiseList}>
            <li className={styles.expertiseItem} key="Save time">
              <p className={styles.expertiseItemTitle}>Save time</p>
              <p className={styles.expertiseItemText}>
                In the USA, it takes 60 days on average to hire engineers locally. Two months is a
                long time for projects with tight deadlines. Brocoders can get the right talent for
                you within 7 days.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Be flexible">
              <p className={styles.expertiseItemTitle}>Be flexible</p>
              <p className={styles.expertiseItemText}>
                Short-term or long-term – you can hire developers for as long as you need them and
                cut the costs of making full-time hires. Also, you can extend your team whenever
                required.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Pick out the best">
              <p className={styles.expertiseItemTitle}>Pick out the best</p>
              <p className={styles.expertiseItemText}>
                Based on your requirements, we provide the most relevant candidates for you to
                review. You can do your own vetting and interview every candidate to make sure they
                fit your project.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Reduce costs">
              <p className={styles.expertiseItemTitle}>Reduce costs</p>
              <p className={styles.expertiseItemText}>
                With us, you eliminate the costs of recruitment, training, office space, software
                licenses, employee benefits, payroll increments, and logistical expenses. You only
                pay for actual work.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Meet deadlines">
              <p className={styles.expertiseItemTitle}>Meet deadlines</p>
              <p className={styles.expertiseItemText}>
                By hiring fast and taking control of your staff, you can speed up your development
                process and meet the deadlines. Our developers will hit the ground running just as
                soon as they join your team.
              </p>
            </li>
            <li className={styles.expertiseItem} key="Get your own team">
              <p className={styles.expertiseItemTitle}>Get your own team</p>
              <p className={styles.expertiseItemText}>
                You know every developer, communicate with them directly and manage their work. We
                cover all paperwork, team setup, and payroll operations, and facilitate productive
                relationships.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section className={mvpStyles.section}>
        <ReviewSlider2 />
      </section>
      <div className="inner">
        <h2 className={styles.awardsCaption}>Awards</h2>
      </div>
      <Awards isGrayBackground={false} isWithCaption={false} />
      <OfferWidget />
      <Booking />
    </Layout>
  );
};

export default TeamAugmentation;
