import React from 'react';
// gatsby
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styles from './two-pizza-team.module.css';
import teamStyles from '../team-augmentation.module.css';

export default function TwoPizzaTeam({ isMobile }: { isMobile: boolean }) {
  const data = useStaticQuery(graphql`
    query {
      dedicatedTeam: file(relativePath: { eq: "dedicatedTeam.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dedicatedTeamMob: file(relativePath: { eq: "dedicatedTeamMob.png" }) {
        childImageSharp {
          fluid(maxWidth: 546, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      usinessAnalysts: file(relativePath: { eq: "usiness-analysts.svg" }) {
        publicURL
      }
      uiDesigners: file(relativePath: { eq: "ui-designers.svg" }) {
        publicURL
      }
      mobileDevelopers: file(relativePath: { eq: "mobile-developers.svg" }) {
        publicURL
      }
      qaSpecialists: file(relativePath: { eq: "qa-specialists.svg" }) {
        publicURL
      }
      devopsEngineers: file(relativePath: { eq: "devops-engineers.svg" }) {
        publicURL
      }
      projectManagers: file(relativePath: { eq: "project-managers.svg" }) {
        publicURL
      }
    }
  `);

  return (
    <div>
      <p className={teamStyles.subtitle}>Get a complete “two-pizza” dedicated development team</p>
      <p className={teamStyles.paragraph}>
        Set up a manageable team to strike the right balance between software quality and delivery
        speed. We bring in the right people with different skill sets and strong experience in all
        the areas of software development from business analysis to testing and DevOps. The team
        will be perfectly coordinated and guided by a senior project manager.
      </p>
      <ul className={styles.specialistList}>
        <li className={styles.specialistItem} key="Business analysts">
          <img
            src={data.usinessAnalysts.publicURL}
            className={styles.icon}
            alt="business analysts "
          />
          <p className={styles.itemCaption}>Business analysts</p>
        </li>
        <li className={styles.specialistItem} key="UI/UX designers">
          <img src={data.uiDesigners.publicURL} className={styles.icon} alt="ui Designers icon" />
          <p className={styles.itemCaption}>UI/UX designers</p>
        </li>
        <li className={styles.specialistItem} key="Web mobile Developers">
          <img
            src={data.mobileDevelopers.publicURL}
            className={styles.icon}
            alt="Web mobile Developers icon"
          />
          <p className={styles.itemCaption}>Web/mobile developers</p>
        </li>
        <li className={styles.specialistItem} key="qa Specialists">
          <img
            src={data.qaSpecialists.publicURL}
            className={styles.icon}
            alt="qa Specialists icon"
          />
          <p className={styles.itemCaption}>QA specialists</p>
        </li>
        <li className={styles.specialistItem} key="devops Engineers">
          <img
            src={data.devopsEngineers.publicURL}
            className={styles.icon}
            alt="devops Engineers icon"
          />
          <p className={styles.itemCaption}>DevOps engineers</p>
        </li>
        <li className={styles.specialistItem} key="project Managers">
          <img
            src={data.projectManagers.publicURL}
            className={styles.icon}
            alt="project Managers icon"
          />
          <p className={styles.itemCaption}>Project managers</p>
        </li>
      </ul>
      {isMobile ? (
        <Img fluid={data.dedicatedTeamMob.childImageSharp.fluid} alt="" />
      ) : (
        <Img fluid={data.dedicatedTeam.childImageSharp.fluid} className={styles.image} alt="" />
      )}
    </div>
  );
}
